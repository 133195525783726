import React from 'react';
import { Box, Typography } from '@mui/material';
import './GameHomePageComponents.scss';
import { useTheme } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import trophyImage from '../../../assets/images/Layer_1.svg';
import SilverTrophy from '../../../assets/images/SilverTrophy.svg';
import BronzeTrophy from '../../../assets/images/BronzeTrophy.svg';

const PoolPrizes = ({ doesUserBelong, prizes }) => {
    
    const whichTrophy = (trophy) => {
        if (trophy === 'gold') {
            return trophyImage;
        } else if (trophy === 'silver') {
            return SilverTrophy;
        } else {
            return BronzeTrophy;
        }
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                backgroundColor: doesUserBelong ? '#fff' : '#002129',
                padding: '16px 0px',
            }}
        >
            <Typography
                sx={{
                    fontSize: '24px',
                    fontWeight: '700',
                    color: doesUserBelong ? '#002129' : '#fff',
                    marginBottom: '16px',
                    textAlign: 'center',
                }}
            >
                POOL PRIZES
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row', // Always use row layout
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '20px', // Space between trophy and text columns
                }}
            >
                {/* Trophy Column */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    {prizes?.prizes?.map((prize, index) => (
                        <img 
                            key={index}
                            src={whichTrophy(prize.trophy)} 
                            alt={prize.trophy} 
                            style={{ 
                                height: isMobile ?  '24px' : '32px',
                            }}
                        />
                    ))}
                </Box>

                {/* Text Column */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '18px',
                    }}
                >
                    {prizes?.prizes?.map((prize, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '5px',
                            }}
                        >
                            <Typography 
                                sx={{ 
                                    color: doesUserBelong ? '#002129' : '#fff',
                                    fontSize: isMobile ? '12px' : '16px',
                                    fontWeight: '700', 
                                }}
                            >
                                {prize.text}
                            </Typography>
                            {prize.prize && (
                                <Typography 
                                    sx={{ 
                                        color: doesUserBelong ? '#002129' : '#fff',
                                        fontSize: isMobile ? '12px' : '16px', 
                                    }}
                                >
                                    - {prize.prize}
                                </Typography>
                            )}
                        </Box>
                    ))}
                </Box>

                {/* Trophy Column */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    {prizes?.prizes?.map((prize, index) => (
                        <img 
                            key={index}
                            src={whichTrophy(prize.trophy)} 
                            alt={prize.trophy} 
                            style={{ 
                                height: isMobile ?  '24px' : '32px',
                            }}
                        />
                    ))}
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '16px',
                }}
            >
                {prizes?.extraText?.map((text, index) => (
                    <Typography
                        key={index}
                        sx={{
                            color: doesUserBelong ? '#002129' : '#fff',
                            fontSize: isMobile ? '12px' : '16px',
                            fontWeight: '700', 
                            textAlign: 'center',
                            marginBottom: '8px',
                        }}
                    >
                        {text.bold}{' '}
                        {text.text && (
                            <Typography
                                component="span"
                                sx={{
                                    color: doesUserBelong ? '#002129' : '#fff',
                                    fontSize: isMobile ? '12px' : '16px',
                                    fontWeight: 400, 
                                }}
                            >
                                - {text.text}
                            </Typography>
                        )}
                    </Typography>                
                ))}
            </Box>
        </Box>
    )
}

export default PoolPrizes;
// College Football Utility Functions
import { DateTime } from 'luxon';

export const gatherLogoCFB = (teamId, teams) => {
    const team = teams?.find(team => team.GlobalTeamID === teamId);
    return team?.TeamLogoUrl;
};

export const gatherStadiumInfoCFB = (stadiumId, stadiums) => {
    const stadium = stadiums?.find(stadium => stadium.StadiumID === stadiumId);
    return `${stadium?.City || ''}, ${stadium?.State || ''}`;
};

export const gatherPickEmCardTopLeftInfoCFB = (gameDescription, roundName) => {
    return `${gameDescription} - ${roundName}`;
};

export const gatherLineDisplayCFB = (homeId, awayId, homeSpreadPicked, awaySpreadPicked, overPicked, underPicked, hasGameStarted, homeLine, awayLine, total, teamsOrNames) => {
    //console.log("Game Info", homeId, awayId, homeSpreadPicked, awaySpreadPicked, overPicked, underPicked, hasGameStarted, homeLine, awayLine, total, teamsOrNames);
    let spreadDisplay = '';
    let totalDisplay = '';
    
    if (homeSpreadPicked || awaySpreadPicked) {
        if (homeSpreadPicked) {
            const homeTeam = teamsOrNames?.find(team => team.GlobalTeamID === homeId)?.ShortDisplayName;
            const homeSpread = homeLine === undefined ? "Off Board" : (homeLine > 0 ? `+${homeLine}` : homeLine === 0 ? 'PK' : homeLine);
            spreadDisplay = `${homeTeam} (${homeSpread})`;
        } else {
            const awayTeam = teamsOrNames?.find(team => team.GlobalTeamID === awayId)?.ShortDisplayName;
            const awaySpread = awayLine === undefined ? "Off Board" : (awayLine > 0 ? `+${awayLine}` : awayLine === 0 ? 'PK' : awayLine);
            spreadDisplay = `${awayTeam} (${awaySpread})`;
        }
    }
    
    if (overPicked || underPicked) {
        const totalValue = total === undefined ? "Off Board" : total;
        totalDisplay = `O/U (${totalValue})`;
    }    

    if (spreadDisplay !== '' && totalDisplay !== '') {
        return `${spreadDisplay}\u00A0\u00A0-\u00A0\u00A0${totalDisplay}`;
    } else if (spreadDisplay !== '') {
        return spreadDisplay;
    } else if (totalDisplay !== '') {
        return totalDisplay;
    } else {
        return '';
    }
}

export const gatherDisplayInfoCFB = (teamId, teams, seeds, isGameOver) => {
    const team = teams?.find(team => team.GlobalTeamID === teamId);
    const seed = seeds?.find(seed => seed.GlobalTeamID === teamId)?.seed;
    const school = team?.School;
    const name = team?.Name;
    const wins = team?.Wins;
    const losses = team?.Losses;
    const display = !isGameOver 
    ? `${seed !== undefined ? `(${seed}) ` : ""}${school} ${name} (${wins}-${losses})`
    : `${seed !== undefined ? `(${seed}) ` : ""}${school} ${name}`;
    return display;
}

export const gatherScoresCFB = (game) => {
    const homeScore = game?.HomeTeamScore;
    const awayScore = game?.AwayTeamScore;
    return { homeScore, awayScore };
}


export const getBracketInfoPreLockCFB = (teamId, teams, seeds, names) => {
    const team = teams?.find((t) => t.GlobalTeamID === teamId);
    const seed = seeds?.find((s) => s.GlobalTeamID === teamId)?.seed;
    const logo = team?.TeamLogoUrl;
    const name = team?.School;
    const record = team?.Wins + "-" + team?.Losses;
    const teamColor = names?.find((n) => n.GlobalTeamID === teamId)?.PrimaryColor;
    const nameDisplay = name + " (" + record + ")";
    const teamDisplayCharacterCount = nameDisplay?.length;

    return {
        teamId,
        seed,
        logo,
        nameDisplay,
        teamDisplayCharacterCount,
        teamColor,
    };
};


export const getBracketInfoPostLockCFB = (teamId, teams, seeds, names) => {
    if (!teamId) {
        return null;
    }
    const team = teams?.find((t) => t.GlobalTeamID === teamId);
    const seed = seeds?.find((s) => s.GlobalTeamID === teamId)?.seed;
    const logo = team?.TeamLogoUrl;
    const name = team?.School;
    const teamColor = names?.find((n) => n.GlobalTeamID === teamId)?.PrimaryColor;
    const nameDisplay = name;
    const teamDisplayCharacterCount = nameDisplay?.length;

    return {
        teamId,
        seed,
        logo,
        nameDisplay,
        teamDisplayCharacterCount,
        teamColor,
    };
};



export const getPickStatsGameInfoCFB = (gameId, schedule, teams, teamsOrNames) => {
    const game = schedule?.find(game => game?.GlobalGameID === gameId);
    const awayTeamId = game?.GlobalAwayTeamID;
    const homeTeamId = game?.GlobalHomeTeamID;
    const gameStatus = game?.Status;
    const dateTime = game?.DateTime;
    const timeNowInNewYork = DateTime.now().setZone('America/New_York');
    const formattedDate = DateTime.fromISO(dateTime)
        .setZone('America/New_York')  // Ensure it's in New York time zone (ET)
        .toFormat('EEE, MMM dd h:mm a ZZZZ');
    const dateTimeInNewYork = DateTime.fromISO(dateTime).setZone('America/New_York');
    const hasGameStarted = dateTimeInNewYork < timeNowInNewYork;
    const homeScore = game?.HomeTeamScore;
    const awayScore = game?.AwayTeamScore;
    const homePointSpread = game?.PointSpread;
    const overUnder = game?.OverUnder;
    const awayLogo = teams?.find(team => team?.GlobalTeamID === awayTeamId)?.TeamLogoUrl;
    const homeLogo = teams?.find(team => team?.GlobalTeamID === homeTeamId)?.TeamLogoUrl;
    const awayName = teamsOrNames?.find(team => team?.GlobalTeamID === awayTeamId)?.ShortDisplayName;
    const homeName = teamsOrNames?.find(team => team?.GlobalTeamID === homeTeamId)?.ShortDisplayName;
    const title = game?.Title;

    return {
        awayTeamId,
        homeTeamId,
        gameStatus,
        dateTime,
        homeScore,
        awayScore,
        homePointSpread,
        overUnder,
        awayLogo,
        homeLogo,
        awayName,
        homeName,
        hasGameStarted,
        formattedDate,
        title
    };
}




export const getPickStatsPickInfoCFB = (gameId, awayTeamId, homeTeamId, leaderboard) => {
    const stats = {
        away: { count: 0, wins: 0, losses: 0, pushes: 0 },
        home: { count: 0, wins: 0, losses: 0, pushes: 0 },
        over: { count: 0, wins: 0, losses: 0, pushes: 0 },
        under: { count: 0, wins: 0, losses: 0, pushes: 0 }
    };

    leaderboard?.forEach(user => {
        const spreadPick = user?.dailyPicks?.find(pick => 
            pick?.gameId === gameId && pick?.pickType === "Spread"
        );
        if (spreadPick) {
            const side = spreadPick.pick === awayTeamId ? 'away' : 'home';
            stats[side].count++;
            if (spreadPick.result === "Win") stats[side].wins++;
            else if (spreadPick.result === "Loss") stats[side].losses++;
            else if (spreadPick.result === "Push") stats[side].pushes++;
        }

        const totalPick = user?.dailyPicks?.find(pick => 
            pick?.gameId === gameId && pick?.pickType === "Total"
        );
        if (totalPick) {
            const side = totalPick.pick === "Over" ? 'over' : 'under';
            stats[side].count++;
            if (totalPick.result === "Win") stats[side].wins++;
            else if (totalPick.result === "Loss") stats[side].losses++;
            else if (totalPick.result === "Push") stats[side].pushes++;
        }
    });

    // Helper function to determine result (Win, Loss, Push, or Split)
    const getResult = (wins, losses, pushes) => {
        const nonZeroCount = [wins, losses, pushes].filter(count => count > 0).length;
        if (nonZeroCount > 1) return "Split";  // More than one non-zero value means "Split"
        if (wins > 0) return "Win";
        if (losses > 0) return "Loss";
        if (pushes > 0) return "Push";
        return "No Result";  // If none have a value > 0, return "No Result"
    };

    return {
        awayCount: stats.away.count,
        awayWins: stats.away.wins,
        awayLosses: stats.away.losses,
        awayPushes: stats.away.pushes,
        awayResult: getResult(stats.away.wins, stats.away.losses, stats.away.pushes),  // Added awayResult

        homeCount: stats.home.count,
        homeWins: stats.home.wins,
        homeLosses: stats.home.losses,
        homePushes: stats.home.pushes,
        homeResult: getResult(stats.home.wins, stats.home.losses, stats.home.pushes),  // Added homeResult

        overCount: stats.over.count,
        overWins: stats.over.wins,
        overLosses: stats.over.losses,
        overPushes: stats.over.pushes,
        overResult: getResult(stats.over.wins, stats.over.losses, stats.over.pushes),  // Added overResult

        underCount: stats.under.count,
        underWins: stats.under.wins,
        underLosses: stats.under.losses,
        underPushes: stats.under.pushes,
        underResult: getResult(stats.under.wins, stats.under.losses, stats.under.pushes)  // Added underResult
    };
};
import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import BracketGame from "../BracketComponents/BracketGame";
import './TwelveTeamBracket.scss';
import FourRoundNav from "../../SuperPickem/MyBracket/MyBracketNavs/FourRoundNav";
import BracketChamp from "../BracketComponents/BracketChamp";
import MyBracketSubmitBar from "../../SuperPickem/MyBracket/MyBracketSubmitBar";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { getBracketHeaderInfo } from "../../SuperPickem/utils/bracketUtils";
import { has, isEqual } from "lodash";
import { DateTime } from "luxon";
import BracketTiebreakerInfo from "../BracketComponents/BracketTiebreakerInfo";

const TwelveTeamBracket = ({
    myUserId,
    mainGameId,
    gameAddress,
    gameType,
    teams,
    names,
    stadiums,
    schedule,
    games,
    seeds,
    odds,
    rounds,
    bracketState,
    setBracketState,
    userBracketPicksFromDB,
    season,
    logo,
    league,
    gatherLogo,
    gatherStadiumInfo,
    gatherDisplayInfo,
    gatherScores,
    gatherLineDisplay,
    stopPicksWarning,
    gameRewards,
    joinReward,
    unsavedBracketPicks,
    setUnsavedBracketPicks,
    getBracketInfoPreLock,
    selectionShowTime,
    selectionShowText,
    countdownDeadline,
    bracketLogo,
    bracketTiebreakerInfo,
}) => {

    //console.log("Countdown Deadline:", countdownDeadline);
    const theme = useTheme();
    const isMobile = useMediaQuery("(max-width:900px)");

    const [bracketRound, setBracketRound] = useState(1);
    const [roundDash, setRoundDash] = useState(1);
    const [submitBarOpen, setSubmitBarOpen] = useState(false);
    //console.log("Bracket Round: ", bracketRound, "Round Dash: ", roundDash);

    const picksByRound = [4, 4, 2, 1];

    const nowInNewYork = DateTime.now().setZone('America/New_York');
    const selectionShowTimeInNewYork = DateTime.fromISO(selectionShowTime).setZone('America/New_York');
    const hasSelectionShowPassed = nowInNewYork > selectionShowTimeInNewYork;
    const formattedSelectionTime = DateTime.fromISO(selectionShowTime, { zone: 'America/New_York' })
    .toFormat("ccc M/d '@' h:mma 'ET'");

    const countdownTimeInNewYork = DateTime.fromISO(countdownDeadline).setZone('America/New_York');
    const hasCountdownPassed = nowInNewYork > countdownTimeInNewYork;
    const formattedCountdownTime = DateTime.fromISO(countdownDeadline, { zone: 'America/New_York' })
    .toFormat("ccc M/d '@' h:mma 'ET'");



    const roundOneRef = useRef(null);
    const roundTwoRef = useRef(null);
    const roundThreeRef = useRef(null);
    const roundFourRef = useRef(null);
    const roundFiveRef = useRef(null);
    //console.log("Round 1 Ref:", roundOneRef.current); // Check if the ref is set correctly
    //console.log("Round 2 Ref:", roundTwoRef.current); // Check if the ref is set correctly

    // Use Effect to see if userBracketPicksFromDB is different from bracketState
    // If it is, setSubmitBarOpen to true, otherwise set it to false
    useEffect(() => {
        //console.log("Checking Bracket Picks");
        if (userBracketPicksFromDB && bracketState) {
            const picksAreEqual = 
                userBracketPicksFromDB.length === bracketState.length &&
                userBracketPicksFromDB.every(userPick =>
                    bracketState.some(bracketPick => isEqual(userPick, bracketPick))
                );
            setSubmitBarOpen(!picksAreEqual);
            setUnsavedBracketPicks(!picksAreEqual);
        }
    }, [userBracketPicksFromDB, bracketState]);

    const observerRef = useRef(null); // Invisible observer ref
    const containerRef = useRef(null); // Sticky container ref
    const [isStuck, setIsStuck] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (observerRef.current && containerRef.current) {
                const observerPosition = observerRef.current.getBoundingClientRect();
                setIsStuck(observerPosition.top <= 80); // Freeze when 80px from the top
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    
    const headerInfo = getBracketHeaderInfo(rounds, userBracketPicksFromDB);
    //console.log("Header Info:", headerInfo);

    const renderGames = (count, round, spacing = 'normal') => (
        Array(count).fill().map((_, index) => (
            <React.Fragment key={index}>
                <BracketGame 
                    games={games}
                    rounds={rounds}
                    teams={teams}
                    names={names}
                    seeds={seeds}
                    game={index + 1}
                    round={round}
                    picksByRound={picksByRound}
                    bracketState={bracketState}
                    setBracketState={setBracketState}
                    getBracketInfoPreLock={getBracketInfoPreLock}
                    setBracketRound={setBracketRound}
                    roundDash={roundDash}
                    setRoundDash={setRoundDash}
                    setRoundDashPosition={{
                        1: () => roundOneRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }),
                        2: () => roundTwoRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }),
                        3: () => roundThreeRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }),
                        4: () => roundFourRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }),
                        5: () => roundFiveRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }),
                    }}
                />
                {index !== count - 1 && (
                    <div className={`bracket-game-spacer${spacing === 'large' ? '-large' : ''}`} />
                )}
            </React.Fragment>
        ))
    );

    const renderConnector = (className) => (
        <div className={`bracket-connector ${className}`} />
    );


    

    return (
        
        <div>
            {isMobile && (
                <div style={{ position: 'relative', zIndex: 10 }}>
                {/* Invisible observer div */}
                    <div
                        ref={observerRef}
                        style={{
                            height: '1px',
                            position: 'relative',
                        }}
                    />
                    {/* Sticky container */}
                    
                    <div
                        ref={containerRef}
                        className={`sticky-scroll-container ${isStuck ? 'stuck' : ''}`}
                        style={{
                            height: '25px',
                            backgroundColor: '#fff',
                            width: '100%',
                            position: isStuck ? 'fixed' : 'relative', // Dynamically set sticky behavior
                            top: isStuck ? '80px' : '0',
                            marginBottom: '16px',
                            zIndex: 10,
                        }}
                    >
                        {/* Place FourRoundNav inside */}
                        <FourRoundNav
                            rounds={rounds}
                            bracketRound={bracketRound}
                            setBracketRound={setBracketRound}
                            roundDash={roundDash}
                            setRoundDash={setRoundDash}
                            setRoundDashPosition={{
                                1: () => roundOneRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start', offsetTop: '-15px' }),
                                2: () => roundTwoRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }),
                                3: () => roundThreeRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }),
                                4: () => roundFourRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }),
                                5: () => roundFiveRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }),
                            }}
                        />
                    </div>
                </div>
            )}

            {!hasSelectionShowPassed && (
                <Box className="bracket-deadline-header">
                    {selectionShowText}: {formattedSelectionTime}
                </Box>
            )}

            {hasSelectionShowPassed && !hasCountdownPassed && (
                <Box className="bracket-deadline-header">
                    Bracket Deadline: {formattedCountdownTime}
                </Box>
            )}
            
            <div className="bracket">
                {/* First round - left side */}
                
                <div className="bracket-column bracket-column-games-first" ref={roundOneRef}>
                    <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: '30px', position: 'relative'  }}>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700'}}>{headerInfo[0]?.name}</Typography>
                        <Typography sx={{ fontSize: '20px', fontWeight: '800' }}>
                            ({headerInfo[0]?.pointsThisRound} Point{headerInfo[0]?.pointsThisRound !== 1 ? 's' : ''})
                        </Typography>
                    </Box>
                    {renderGames(4, 1)}
                </div>

                {/* First round connectors */}
                <div className="bracket-column">
                    {renderConnector('bracket-connector-first')}
                    {renderConnector('bracket-connector-first')}
                </div>

                {/* Second round - left side */}
                <div className="bracket-column bracket-column-games-second" ref={roundTwoRef}>
                    <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: '65px', position: 'relative'}}>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{headerInfo[1]?.name}</Typography>
                        <Typography sx={{ fontSize: '20px', fontWeight: '800' }}>
                            ({headerInfo[1]?.pointsThisRound} Point{headerInfo[1]?.pointsThisRound !== 1 ? 's' : ''})
                        </Typography>
                    </Box>
                    {renderGames(4, 2)}
                </div>

                {/* Second round connectors */}
                <div className="bracket-column">
                    {renderConnector('bracket-connector-second')}
                    {renderConnector('bracket-connector-second')}
                </div>

                {/* Third round connectors - left */}
                <div className="bracket-column">
                    {renderConnector('bracket-connector-third')}
                </div>

                {/* Semi-finals */}
                <div className="bracket-column bracket-column-games-third" ref={roundThreeRef}>
                        <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: '156px', position: 'relative'}}>
                            <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{headerInfo[2]?.name}</Typography>
                            <Typography sx={{ fontSize: '20px', fontWeight: '800' }}>
                                ({headerInfo[2]?.pointsThisRound} Point{headerInfo[2]?.pointsThisRound !== 1 ? 's' : ''})
                            </Typography>
                        </Box>
                    {renderGames(2, 3, 'large')}
                </div>

                {/* Third round connectors - right */}
                <div className="bracket-column">
                    {renderConnector('bracket-connector-third bracket-connector-third-right')}
                </div>

                {/* Final connector */}
                <div className="bracket-column">
                    {renderConnector('bracket-connector-final')}
                </div>

                {/* Championship game */}
                <div className="bracket-column bracket-column-games-final" ref={roundFourRef}>
                    <Box sx={{ width: '230px', marginBottom: '24px', paddingBottom: '114px', position: 'relative'}}>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{headerInfo[3]?.name}</Typography>
                        <Typography sx={{ fontSize: '20px', fontWeight: '800' }}>
                            ({headerInfo[3]?.pointsThisRound} Point{headerInfo[3]?.pointsThisRound !== 1 ? 's' : ''})
                        </Typography>
                    </Box>
                    <Box>
                        <img
                            src={bracketLogo}
                            alt="Bracket Logo"
                            style={{
                                width: '185px',
                                height: '219.82px',
                                margin: '0 auto',
                                paddingBottom: '112px',
                            }}
                        />
                    </Box>
                    {renderGames(1, 4)}
                </div>

                {/* Championship connector */}
                <div className="bracket-column bracket-column-games-champ">
                    <Box sx={{ width: '230px', marginBottom: '218px', paddingBottom: '10px', position: 'relative'}}>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>Champ</Typography>
                        <Typography sx={{ fontSize: '20px', fontWeight: '800' }}>&nbsp;</Typography>
                    </Box>
                    {/* {renderConnector('bracket-connector-champ')} */}
                
                    
                    <Box ref={roundFiveRef}>
                        <BracketChamp
                            games={games}
                            rounds={rounds}
                            teams={teams}
                            names={names}
                            seeds={seeds}
                            bracketState={bracketState}
                            setBracketState={setBracketState}
                            getBracketInfoPreLock={getBracketInfoPreLock}
                        />
                    </Box>

                    <BracketTiebreakerInfo
                        bracketTiebreakerInfo={bracketTiebreakerInfo}
                    />

                    
                </div>
                
            </div>

            {submitBarOpen && (
                <MyBracketSubmitBar
                    myUserId={myUserId}
                    gameType={gameType}
                    mainGameId={mainGameId}
                    bracketState={bracketState}
                    setBracketState={setBracketState}
                    userBracketPicksFromDB={userBracketPicksFromDB}
                    season={season}
                    league={league}
                    logo={logo}
                    seeds={seeds}
                    gameRewards={gameRewards}
                    joinReward={joinReward}
                    countdownDeadline={countdownDeadline}
                />
            )}
        </div>
    );
};

export default TwelveTeamBracket;
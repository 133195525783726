import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import { useCFBSuperPickemContext } from './CFBSuperPickemContext';
import DynamicPoolHeaderBar from '../../PoolHeaderBar/DynamicPoolHeaderBar';
import SuperPickemMyPicks from '../MyPicks/SuperPickemMyPicks';
import BowlGamesMyPicks from '../MyPicks/BowlGamesMyPicks';
import TwelveTeamBracket from '../../Brackets/TwelveTeamBracket/TwelveTeamBracket';
import { DateTime } from 'luxon';
import GameRulesPage from '../../GameRulesPage/GameRulesPage';
import PartyDropdown from '../../PartyDropdown/MyPartyDropdown';
import GameHomePageNew from '../../GameHomePage/GameHomePageNew';
import GameSponsorHeader from '../../PoolHeaderBar/GameSponsorHeader';
import JoinGameModal from '../../GameModals/JoinGameModal/JoinGameModal';
import MyParty from '../../MyParty/MyParty';
import SponsorTab from '../../SponsorTab/SponsorTab';
import SuperPickemNav from '../SuperPickemNav/SuperPickemNav';
import { gatherLogoCFB, gatherStadiumInfoCFB, gatherDisplayInfoCFB, gatherScoresCFB, gatherLineDisplayCFB, getBracketInfoPreLockCFB, getBracketInfoPostLockCFB, getPickStatsGameInfoCFB, getPickStatsPickInfoCFB, gatherPickEmCardTopLeftInfoCFB } from '../utils/cfbUtils';
import "./CFBContainerStyles.scss";
import TwelveTeamBracketLive from '../../Brackets/TwelveTeamBracket/TwelveTeamBracketLive';
import SuperPickemUserDash from '../UserDash/SuperPickemUserDash';
import BowlUserDash from '../UserDash/BowlUserDash';
import SuperPickemLeaderboard from '../Leaderboard/SuperPickemLeaderboard';
import SuperPickemPoolPicks from '../PoolPicks/SuperPickemPoolPicks';
import BowlPickemPoolPicks from '../PoolPicks/BowlPickEmPoolPicks';
import QuickPickRewards from '../../QuickPickGames/Rewards/QuickPickRewards';
import RewardsRedeemHeader from '../../QuickPickGames/Rewards/RewardsRedeemHeader';
import { Typography } from '@mui/material';
import GameLogoDisplay from '../../GameHomePage/GameHomePageComponents/GameLogoDisplay';
import ReferralsLeaderboard from '../../ReferralsLeaderboard/ReferralsLeaderboard';



const CFBSuperPickemContainer = () => {
    const {
        myUserId,
        mainGameId,
        gameType,
        league,
        gameData,
        leaderboardData,
        cfbTeams,
        cfbNames,
        cfbStadiums,
        schedule,
        games,
        seeds,
        odds,
        rounds,
        highestRound,
        picking,
        gameDescription,
        pickState,
        setPickState,
        bracketState,
        setBracketState,
        clickState,
        setClickState,
        interval,
        setInterval,
        signUpState,
        setSignUpState,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        defaultPartyId,
        setDefaultPartyId,
        defaultPartyName,
        setDefaultPartyName,
        joinOpen,
        setJoinOpen,
        doesUserBelong,
        userSubgroups,
        totalReferrals,
        newSignUps,
        gameAddress,
        gameName,
        gameStartTime,
        formattedGameStartTime,
        sponsorName,
        gameRewards,
        myUsername,
        userPicksFromDB,
        userBracketPicksFromDB,
        rewards,
        joinReward,
        handleCreateSubgroup, 
        picksByInterval,
        season,
        userData,
        currentRound,
        hasJoinDeadlinePassed,
        isGameOver,
        stopPicksWarning,
        countdownDeadline,
        countdownTimer,
        countdownMessage,
        firstSubgroupId,
        firstSubgroupName,
        tiebreakerInfo,
        selectionShowTime,
        selectionShowText,
        gameLogoDisplayInfo,
        gameReferrals,
        referralPrizes,
        bracketLogo,
        pickemLogo,
        bracketTiebreakerInfo,
        amIOptedInToTexts,
        bowlGames,
        bowlInfo,
        playoffPicks,
        bowlPicks,
        firstBowlTime,
        countdownText,
        countdownBackground,
    } = useCFBSuperPickemContext();
    //console.log("Countdown Text:", countdownText, "Countdown Background:", countdownBackground);

    //console.log("User Subgroups:", userSubgroups, "First Subgroup ID:", firstSubgroupId, "First Subgroup Name:", firstSubgroupName, "Default Party ID:", defaultPartyId, "Default Party Name:", defaultPartyName, "PartyID:", partyId, "Main Game ID:", mainGameId, "Party State:", partyState);

    const howManyRounds = rounds?.length;

    const getUserPointsAndRanks = (userId, leaderboardData) => {
        // Find the user data once
        const user = leaderboardData?.find((user) => user.userId === userId) || {};
    
        // Helper function to check if a rank is tied
        const isTied = (rank, key) => {
            // Log inputs and current data state
            //console.log(`Checking tie for rank: ${rank}, key: ${key}`);
            
            const tiedUsers = leaderboardData?.filter((user) => user[key] === rank);
            
            // Log filtered results and their count
            //console.log(`Filtered users with rank ${rank} on ${key}:`, tiedUsers);
            //console.log(`Count of users with rank ${rank} on ${key}:`, tiedUsers?.length);
    
            return tiedUsers?.length > 1;
        };
    
        // Extract relevant information
        return {
            myRank: user.overallRank || null,
            myBracketRank: user.bracketRank || null,
            myPicksRank: user.dailyPicksRank || null,
            myTotalPoints: user.totalPoints || 0,
            myBracketPoints: user.bracketPoints || 0,
            myPicksPoints: user.dailyPicksPoints || 0,
            overallTied: isTied(user.overallRank, 'overallRank'),
            bracketTied: isTied(user.bracketRank, 'bracketRank'),
            picksTied: isTied(user.dailyPicksRank, 'dailyPicksRank'),
        };
    };
    

    let fullLeaderboard = leaderboardData ? leaderboardData : [];

    const totalEntrants = fullLeaderboard?.length;

    const [leaderboard, setLeaderboard] = useState(fullLeaderboard);
    //console.log("Leaderboard:", leaderboard);
    

    //Helper function to assign ranks to users
    const assignSubgroupRanks = (leaderboardData) => {
        // Function to calculate ranks for a given sorted array
        const calculateRanks = (sortedArray, key) => {
            const ranks = Array(sortedArray.length).fill(0);
            let rank = 1;
            for (let i = 0; i < sortedArray.length; i++) {
                if (i > 0 && sortedArray[i][key] === sortedArray[i - 1][key]) {
                    ranks[i] = ranks[i - 1]; // Tie handling: same rank as previous
                } else {
                    ranks[i] = rank;
                }
                rank++;
            }
        return ranks;
        };

        // Sorting data for each ranking criterion
        const sortedByTotal = [...leaderboardData].sort((a, b) => b.totalPoints - a.totalPoints);
        const sortedByBracket = [...leaderboardData].sort((a, b) => b.bracketPoints - a.bracketPoints);
        const sortedByDailyPicks = [...leaderboardData].sort((a, b) => b.dailyPicksPoints - a.dailyPicksPoints);

        // Calculate ranks for each category
        const totalRanks = calculateRanks(sortedByTotal, 'totalPoints');
        const bracketRanks = calculateRanks(sortedByBracket, 'bracketPoints');
        const dailyPicksRanks = calculateRanks(sortedByDailyPicks, 'dailyPicksPoints');

        // Map ranks back to the original users
        const userRanks = leaderboardData.map(user => {
            const overallRank = totalRanks[sortedByTotal.findIndex(u => u === user)];
            const bracketRank = bracketRanks[sortedByBracket.findIndex(u => u === user)];
            const dailyPicksRank = dailyPicksRanks[sortedByDailyPicks.findIndex(u => u === user)];

            return {
                ...user,
                overallRank,
                bracketRank,
                dailyPicksRank
            };
        });

        return userRanks;
    };



    useEffect(() => {
        // Only run this logic if partyId is not equal to the mainGameId
        if (partyId !== mainGameId) {
            const subgroups = gameData?.subGroups;
            const filteredSubgroups = subgroups?.filter(subgroup => subgroup.groupID === partyId);
            const filteredUserIds = filteredSubgroups?.[0]?.groupMembers;
    
            // Filter the leaderboard based on filteredUserIds
            const filteredLeaderboard = fullLeaderboard?.filter(user => filteredUserIds?.includes(user.userId));
            const rankedLeaderboard = assignSubgroupRanks(filteredLeaderboard);
    
            // Only update state if the leaderboard has changed
            setLeaderboard(prevLeaderboard => {
                const isSame = JSON.stringify(prevLeaderboard) === JSON.stringify(rankedLeaderboard);
                return isSame ? prevLeaderboard : rankedLeaderboard;
            });
        } else {
            // If partyId equals mainGameId, set to fullLeaderboard
            setLeaderboard(prevLeaderboard => {
                const isSame = JSON.stringify(prevLeaderboard) === JSON.stringify(fullLeaderboard);
                return isSame ? prevLeaderboard : fullLeaderboard;
            });
        }
    }, [partyId, fullLeaderboard, gameData?.subGroups]);
    
    
    // Pass data as props
    const myInfo = getUserPointsAndRanks(myUserId, fullLeaderboard);

    const [unsavedPicks, setUnsavedPicks] = useState(false);
    const [unsavedBracketPicks, setUnsavedBracketPicks] = useState(false);

    const { component, pageState, optionalState } = useParams();

    const location = useLocation();
    const navigateToTop = useNavigateToTop();

    const [referralId, setReferralId] = useState(null);
    const [newSignUp, setNewSignUp] = useState(false);

    const [selectedUserToView, setSelectedUserToView] = useState(null);

    useEffect(() => {
        //console.log("Setting Selected User to View");
        if (component === 'BRACKET' && leaderboardData?.length > 0) {
            const selectedUserId = pageState;
            const user = leaderboardData?.find(user => user.userId === selectedUserId);
            //console.log("Selected User to View:", user, selectedUserId);
            setSelectedUserToView(user || []);
        }
    }, [component, optionalState, leaderboardData]);
    

    useEffect(() => {
        //console.log("Running Referral Use Effect");
        const queryParams = new URLSearchParams(location.search);
        const referralId = queryParams.get('referralId');
        const newSignUp = queryParams.get('signUp');
        if (referralId) {
            setReferralId(referralId);
        }
        if (newSignUp) {
            setNewSignUp(true);
        }
    }, [location.search]);

    const timeNowInNewYork = DateTime.now().setZone('America/New_York');
    const anyUnredeemedRewards = rewards?.some(reward =>
        !reward.redeemed && DateTime.fromISO(reward.expiration) > timeNowInNewYork
    );

    
    const hasBracketDeadlinePassed = DateTime.fromISO(countdownDeadline) < timeNowInNewYork;
    //console.log("Has Bracket Deadline Passed:", hasBracketDeadlinePassed);

    useEffect(() => {
        //console.log("Updating Click State");
        //Update the clickState whenever the URL changes
        if (component) {
            setClickState(component);
        }
    }, [component, location]);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const logo = gameData?.clubLogo;
    const sponsorSecondaryLogo = gameData?.sponsorInfo?.sponsorSecondaryLogo;
    const sponsorLogo = gameData?.sponsorInfo?.sponsorLogo;
    const sponsorHeaderLogo = gameData?.sponsorInfo?.sponsorHeaderLogo;
    const widerLogo = gameData?.sponsorInfo?.widerLogo;
    const sponsorBannerText = gameData?.sponsorInfo?.sponsorBannerText;
    const sponsorAbbreviation = gameData?.sponsorInfo?.sponsorAbbreviation;
    

    //MAKE THESE DYNAMIC ONCE LEADERBOARD IS SET UP
    const whosInFirst = "Winner";
    const whosInFirstCharacterCount = 6;
    const whosInSecond = "Runner-Up";
    const whosInSecondCharacterCount = 8;
    const whosInThird = "Third Place";
    const whosInThirdCharacterCount = 10;
    const howManyWinners = 3;

    const homePageProps = { signUpState, setSignUpState, gameAddress, userData, gameData, setInterval, currentInterval: currentRound, userSubgroups, doesUserBelong, myUserId, partyState, setPartyState, partyId, setPartyId, setDefaultPartyId, setDefaultPartyName, gameType, mainGameId, joinOpen, setJoinOpen, whosInFirst, whosInFirstCharacterCount, whosInSecond, whosInSecondCharacterCount, whosInThird, whosInThirdCharacterCount, isGameOver, hasJoinDeadlinePassed, howManyWinners, referralId, countdownTimer, countdownMessage, timerDateTime: countdownDeadline, fullLeaderboard, referralPrizes, amIOptedInToTexts, countdownText, countdownBackground, firstBowlTime, bowlPicks }

    //console.log("Picks By Interval:", picksByInterval, "interval:", interval);
    const picksThisInterval = picksByInterval?.find(picks => picks.interval === interval)?.picks;
    //console.log("Picks This Interval:", picksThisInterval);

    const picksThisIntervalInDB = userPicksFromDB?.filter(pick => pick.interval === interval);
    const picksThisIntervalInState = pickState?.filter(pick => pick.interval === interval);
    
    //Reset unsaved picks when game id changes
    useEffect(() => {
        //console.log("Resetting unsaved picks");
        if (mainGameId) {
            setUnsavedPicks(false);
        }
    }, [mainGameId]);

    const handleClickState = (click) => {
        //console.log("Handle Click State: ", click);
        setClickState(click);
        let pageStateToUse = partyId;
        if (click === 'MY_PICKS') {
          pageStateToUse = 'CURRENT';
          setInterval(currentRound);
          setPartyState(defaultPartyName);
          setPartyId(defaultPartyId);
        } else if (click === 'HOME') {
          pageStateToUse = 'HOME';
          setPartyState(defaultPartyName);
          setPartyId(defaultPartyId);
        }

        if (click === 'MY_PARTY' && userSubgroups.length === 0) {
          pageStateToUse = 'NO_PARTY';
        } else if (click === 'MY_PARTY' && partyId === mainGameId) {
            //console.log("Party ID is Main Game ID");
          if (defaultPartyName !== 'Pool Party' && defaultPartyName !== sponsorName) {
            pageStateToUse = defaultPartyId;
            setPartyState(defaultPartyName);
            setPartyId(defaultPartyId);
          } else {
            pageStateToUse = firstSubgroupId;
            setPartyState(firstSubgroupName);
            setPartyId(firstSubgroupId);
          }
        }

        // if (click === 'LEADERBOARD') {
        //     pageStateToUse = defaultPartyId;
        //     setPartyState(defaultPartyName);
        //     setPartyId(defaultPartyId);
        // }

        if (click === 'REWARDS') {
          pageStateToUse = 'ELIGIBLE';
        }
    
        if (click === 'PRESENTED_BY') {
          pageStateToUse = `${gameData?.sponsorInfo?.sponsorAbbreviation} Story`;
        }
        navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded(click)}/${uriEncoded(pageStateToUse)}`);
      }

    return (
        <div>
            {sponsorLogo && (
                <GameSponsorHeader
                    sponsorLogo={sponsorHeaderLogo} 
                    sponsorBannerText={sponsorBannerText} 
                    sponsorName={sponsorName}
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                    myUserId={myUserId}
                    setJoinOpen={setJoinOpen}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    unsavedPicks={unsavedPicks}
                    setUnsavedPicks={setUnsavedPicks}
                    userPicksFromDB={userPicksFromDB}
                    setPickState={setPickState}
                    setPicksToEmpty={true}
                    sponsorAbbreviation={sponsorAbbreviation}
                    setBracketState={setBracketState}
                    userBracketPicksFromDB={userBracketPicksFromDB}
                    unsavedBracketPicks={unsavedBracketPicks}
                    setUnsavedBracketPicks={setUnsavedBracketPicks}
                    dontShowJoinButton={true}
                    widerLogo={widerLogo}
                />
            )}

            {anyUnredeemedRewards && 
                <RewardsRedeemHeader
                    gameAddress={gameAddress}
                    mainGameId={mainGameId}
                />
            }

            <DynamicPoolHeaderBar
                clickState={clickState}
                navigationText={'Home'}
                headerText={gameName}
                gameAddress={gameAddress}
                gameId={mainGameId}
                unsavedPicks={unsavedPicks}
                setUnsavedPicks={setUnsavedPicks}
                userPicksFromDB={userPicksFromDB}
                setPickState={setPickState}
                setBracketState={setBracketState}
                setPicksToEmpty={true}
                userBracketPicksFromDB={userBracketPicksFromDB}
                unsavedBracketPicks={unsavedBracketPicks}
                setUnsavedBracketPicks={setUnsavedBracketPicks}
            />

            {(!doesUserBelong) && (
                <GameLogoDisplay
                    doesUserBelong={doesUserBelong}
                    gameName={gameName}
                    logo={logo}
                    gameLogoDisplayInfo={gameLogoDisplayInfo}
                    setJoinOpen={setJoinOpen}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                />
            )}

            {myUsername && doesUserBelong && playoffPicks &&
                <SuperPickemUserDash
                    userPicksFromDB={userPicksFromDB}
                    userBracketPicksFromDB={userBracketPicksFromDB}
                    doesUserBelong={doesUserBelong}
                    myUsername={myUsername}
                    gameData={gameData}
                    setJoinOpen={setJoinOpen}
                    anyUnredeemedRewards={anyUnredeemedRewards}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                    formattedGameStartTime={formattedGameStartTime}
                    myInfo={myInfo}
                    picksThisInterval={picksThisInterval}
                    picksThisIntervalInDB={picksThisIntervalInDB}
                    teams={cfbTeams}
                    howManyRounds={howManyRounds}
                    hasBracketDeadlinePassed={hasBracketDeadlinePassed}
                    bracketPicksToMake={seeds?.length - 1}
                    myUserId={myUserId}
                    totalEntrants={totalEntrants}
                    userInSubgroups={userSubgroups?.length > 0}
                />
            }

            {myUsername && doesUserBelong && bowlPicks &&
                <BowlUserDash
                userPicksFromDB={userPicksFromDB}
                userBracketPicksFromDB={userBracketPicksFromDB}
                doesUserBelong={doesUserBelong}
                myUsername={myUsername}
                gameData={gameData}
                setJoinOpen={setJoinOpen}
                anyUnredeemedRewards={anyUnredeemedRewards}
                hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                mainGameId={mainGameId}
                gameAddress={gameAddress}
                formattedGameStartTime={formattedGameStartTime}
                myInfo={myInfo}
                picksThisInterval={picksThisInterval}
                picksThisIntervalInDB={picksThisIntervalInDB}
                teams={cfbTeams}
                howManyRounds={howManyRounds}
                hasBracketDeadlinePassed={hasBracketDeadlinePassed}
                bracketPicksToMake={seeds?.length - 1}
                myUserId={myUserId}
                totalEntrants={totalEntrants}
                userInSubgroups={userSubgroups?.length > 0}
                bowlGames={bowlGames}
                firstBowlTime={firstBowlTime}
            />
        }
            <SuperPickemNav
                setClickState={handleClickState}
                clickState={clickState}
                setSignUpState={setSignUpState}
                doesUserBelong={doesUserBelong}
                isGameOver={isGameOver}
                userSubgroups={userSubgroups}
                sponsorAbbreviation={sponsorAbbreviation}
                unsavedPicks={unsavedPicks}
                setUnsavedPicks={setUnsavedPicks}
                userPicksFromDB={userPicksFromDB}
                setPickState={setPickState}
                setBracketState={setBracketState}
                unsavedBracketPicks={unsavedBracketPicks}
                setUnsavedBracketPicks={setUnsavedBracketPicks}
                userBracketPicksFromDB={userBracketPicksFromDB}
                hasJoinDeadlinePassed={hasJoinDeadlinePassed}
            />

            {(userSubgroups?.length > 0 && ((clickState === 'MY_PARTY' && !signUpState) || clickState === 'LEADERBOARD' || clickState === 'POOL_PICKS')) &&
                <PartyDropdown
                    userSubgroups={userSubgroups}
                    gameAddress={gameAddress}
                    partyState={partyState}
                    setPartyState={setPartyState}
                    clickState={clickState}
                    setPartyId={setPartyId}
                    sponsorName={sponsorName}
                />
            }

            {clickState === 'LEADERBOARD' && 
                <SuperPickemLeaderboard 
                    leaderboard={leaderboard} 
                    myUserId={myUserId} 
                    hasBracketDeadlinePassed={hasBracketDeadlinePassed}
                    teams={cfbTeams}
                    gatherLogo={gatherLogoCFB}
                    tiebreakerInfo={tiebreakerInfo}
                    gameAddress={gameAddress}
                    mainGameId={mainGameId}
                    selectedUserToView={selectedUserToView}
                    setSelectedUserToView={setSelectedUserToView}
                    userPicksFromDB={userPicksFromDB}
                    userBracketPicksFromDB={userBracketPicksFromDB}
                    highestRound={highestRound}
                    firstBowlTime={firstBowlTime}
                />
            }

            {clickState === 'REFERRAL_LEADERBOARD' &&
                <ReferralsLeaderboard
                    gameReferrals={gameReferrals}
                    myUsername={myUsername}
                    referralPrizes={referralPrizes}
                />
            }

            {clickState === 'POOL_PICKS' && bowlPicks === true &&
                <BowlPickemPoolPicks
                gameAddress={gameAddress}
                mainGameId={mainGameId}
                currentInterval={currentRound}
                games={games}
                rounds={rounds}
                seeds={seeds}
                leaderboard={leaderboard}
                teams={cfbTeams}
                myUserId={myUserId}
                schedule={schedule}
                gatherLogo={gatherLogoCFB}
                teamsOrNames={cfbNames}
                getPickStatsGameInfo={getPickStatsGameInfoCFB}
                getPickStatsPickInfo={getPickStatsPickInfoCFB}
                getChampInfo={getBracketInfoPostLockCFB}
                hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                countdownDeadline={countdownDeadline}
                pickemLogo={pickemLogo}
                userPicksFromDB={userPicksFromDB}
                bowlGames={bowlGames}
            />
        }

            {clickState === 'POOL_PICKS' && playoffPicks === true &&
                <SuperPickemPoolPicks
                    gameAddress={gameAddress}
                    mainGameId={mainGameId}
                    currentInterval={currentRound}
                    games={games}
                    rounds={rounds}
                    seeds={seeds}
                    leaderboard={leaderboard}
                    teams={cfbTeams}
                    myUserId={myUserId}
                    schedule={schedule}
                    gatherLogo={gatherLogoCFB}
                    teamsOrNames={cfbNames}
                    getPickStatsGameInfo={getPickStatsGameInfoCFB}
                    getPickStatsPickInfo={getPickStatsPickInfoCFB}
                    getChampInfo={getBracketInfoPostLockCFB}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    countdownDeadline={countdownDeadline}
                    pickemLogo={pickemLogo}
                    userPicksFromDB={userPicksFromDB}
                />
            }

            {clickState === 'PRESENTED_BY' && <SponsorTab gameData={gameData} />}

            {clickState === 'HOME' && <GameHomePageNew homePageProps={homePageProps} /> }

            {clickState === 'RULES' && <GameRulesPage gameData={gameData} />}

            {clickState === 'REWARDS' && 
                <QuickPickRewards
                    rewards={rewards}
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                    sponsorHeaderLogo={sponsorHeaderLogo}
                    sponsorName={sponsorName}
                    intervalType={gameData?.intervalType}
                    schedule={schedule}
                    myUserId={myUserId}
                />
            }


            {clickState === 'BRACKET' && !hasBracketDeadlinePassed &&
                <Typography 
                    sx={{ 
                        fontSize: '18px', 
                        fontWeight: 700, 
                        marginTop: '25px', 
                        textAlign: 'center' // Optional, adds better alignment for centered content
                    }}
                >
                    Entrants' brackets are not viewable yet. <br/> Please check back after the first game has started.
                </Typography>
            
            }

            {clickState === 'BRACKET' && hasBracketDeadlinePassed &&
                <TwelveTeamBracketLive
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                    teams={cfbTeams}
                    names={cfbNames}
                    schedule={schedule}
                    games={games}
                    seeds={seeds}
                    rounds={rounds}
                    bracketState={selectedUserToView?.bracketPicks || []}
                    userBracketPicksFromDB={selectedUserToView?.bracketPicks || []}
                    getBracketInfoPostLock={getBracketInfoPostLockCFB}
                    usernameForDisplay={selectedUserToView?.username}
                    bracketLogo={bracketLogo}
                />
            }

            {clickState === 'MY_PICKS' && bowlPicks === true &&
                <BowlGamesMyPicks
                    myUserId={myUserId}
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                    gameType={gameType}
                    teams={cfbTeams}
                    names={cfbNames}
                    stadiums={cfbStadiums}
                    schedule={schedule}
                    games={games}
                    seeds={seeds}
                    odds={odds}
                    rounds={rounds}
                    highestRound={highestRound}
                    picking={picking}
                    gameDescription={gameDescription}
                    pickState={pickState}
                    setPickState={setPickState}
                    bracketState={bracketState}
                    setBracketState={setBracketState}
                    interval={interval}
                    setInterval={setInterval}
                    userPicksFromDB={userPicksFromDB}
                    userBracketPicksFromDB={userBracketPicksFromDB}
                    unsavedPicks={unsavedPicks}
                    setUnsavedPicks={setUnsavedPicks}
                    league={league}
                    gameRewards={gameRewards}
                    joinReward={joinReward}
                    picksThisInterval={picksThisInterval}
                    picksThisIntervalInDB={picksThisIntervalInDB}
                    picksThisIntervalInState={picksThisIntervalInState}
                    season={season}
                    logo={logo}
                    gatherLogo={gatherLogoCFB}
                    gatherStadiumInfo={gatherStadiumInfoCFB}
                    gatherDisplayInfo={gatherDisplayInfoCFB}
                    gatherScores={gatherScoresCFB}
                    stopPicksWarning={stopPicksWarning}
                    gatherLineDisplay={gatherLineDisplayCFB}
                    pickemLogo={pickemLogo}
                    sponsorSecondaryLogo={sponsorSecondaryLogo}
                    bowlGames={bowlGames}
                    bowlInfo={bowlInfo}
                />
            }

            {clickState === 'MY_PICKS' && playoffPicks === true && 
                <SuperPickemMyPicks
                    myUserId={myUserId}
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                    gameType={gameType}
                    teams={cfbTeams}
                    names={cfbNames}
                    stadiums={cfbStadiums}
                    schedule={schedule}
                    games={games}
                    seeds={seeds}
                    odds={odds}
                    rounds={rounds}
                    highestRound={highestRound}
                    picking={picking}
                    gameDescription={gameDescription}
                    pickState={pickState}
                    setPickState={setPickState}
                    bracketState={bracketState}
                    setBracketState={setBracketState}
                    interval={interval}
                    setInterval={setInterval}
                    userPicksFromDB={userPicksFromDB}
                    userBracketPicksFromDB={userBracketPicksFromDB}
                    unsavedPicks={unsavedPicks}
                    setUnsavedPicks={setUnsavedPicks}
                    league={league}
                    gameRewards={gameRewards}
                    joinReward={joinReward}
                    picksThisInterval={picksThisInterval}
                    picksThisIntervalInDB={picksThisIntervalInDB}
                    picksThisIntervalInState={picksThisIntervalInState}
                    season={season}
                    logo={logo}
                    gatherLogo={gatherLogoCFB}
                    gatherStadiumInfo={gatherStadiumInfoCFB}
                    gatherDisplayInfo={gatherDisplayInfoCFB}
                    gatherScores={gatherScoresCFB}
                    stopPicksWarning={stopPicksWarning}
                    gatherLineDisplay={gatherLineDisplayCFB}
                    gatherPickEmCardTopLeftInfo={gatherPickEmCardTopLeftInfoCFB}
                    pickemLogo={pickemLogo}
                    sponsorSecondaryLogo={sponsorSecondaryLogo}
                />
            }

            {clickState === 'MY_PARTY' &&
                <MyParty
                    gameData={gameData}
                    myUserId={myUserId}
                    handleCreateSubgroup={handleCreateSubgroup}
                    userSubgroups={userSubgroups}
                    signUpState={signUpState}
                    setSignUpState={setSignUpState}
                    gameAddress={gameAddress}
                    leaderboardData={leaderboard}
                    partyState={partyState}
                    partyId={partyId}
                    defaultPartyId={defaultPartyId}
                    fullLeaderboard={leaderboardData}
                    doesUserBelong={doesUserBelong}
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    isGameOver={isGameOver}
                />    
            }

            {clickState === 'MY_BRACKET' && !hasBracketDeadlinePassed &&
                <TwelveTeamBracket
                    myUserId={myUserId}
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                    gameType={gameType}
                    teams={cfbTeams}
                    names={cfbNames}
                    stadiums={cfbStadiums}
                    schedule={schedule}
                    games={games}
                    seeds={seeds}
                    odds={odds}
                    rounds={rounds}
                    bracketState={bracketState}
                    setBracketState={setBracketState}
                    userBracketPicksFromDB={userBracketPicksFromDB}
                    season={season}
                    league={league}
                    logo={sponsorSecondaryLogo}
                    gameRewards={gameRewards}
                    joinReward={joinReward}
                    gatherLogo={gatherLogoCFB}
                    gatherStadiumInfo={gatherStadiumInfoCFB}
                    gatherDisplayInfo={gatherDisplayInfoCFB}
                    gatherScores={gatherScoresCFB}
                    stopPicksWarning={stopPicksWarning}
                    gatherLineDisplay={gatherLineDisplayCFB}
                    unsavedBracketPicks={unsavedBracketPicks}
                    setUnsavedBracketPicks={setUnsavedBracketPicks}
                    getBracketInfoPreLock={getBracketInfoPreLockCFB}
                    selectionShowText={selectionShowText}
                    selectionShowTime={selectionShowTime}
                    countdownDeadline={countdownDeadline}
                    bracketLogo={bracketLogo}
                    bracketTiebreakerInfo={bracketTiebreakerInfo}
                />
            }

            {clickState === 'MY_BRACKET' && hasBracketDeadlinePassed &&
                <TwelveTeamBracketLive
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                    teams={cfbTeams}
                    names={cfbNames}
                    schedule={schedule}
                    games={games}
                    seeds={seeds}
                    rounds={rounds}
                    bracketState={bracketState}
                    userBracketPicksFromDB={userBracketPicksFromDB}
                    getBracketInfoPostLock={getBracketInfoPostLockCFB}
                    bracketLogo={bracketLogo}
                />
            }

            {joinOpen && (
                <JoinGameModal
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    gameName={gameName}
                    gameAddress={gameAddress}
                    gameId={mainGameId}
                    gameType={gameType}
                    logo={logo}
                    myUserId={myUserId}
                    setSignUpState={setSignUpState}
                    sponsorSecondaryLogo={sponsorSecondaryLogo}
                    sponsorName={sponsorName}
                    sponsorLogo={sponsorLogo}
                    mainGameId={mainGameId}
                    joinModalText={gameData?.joinModalText}
                    referralId={referralId}
                    newSignUp={newSignUp}
                />
            )}
        </div>
    )
}

export default CFBSuperPickemContainer;
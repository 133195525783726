import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Modal } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "./SuperPickemUserDash.scss";
import bracketTrophy from "../../../assets/images/bracketTrophy.png";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import UserDashCopyLink from "../../GameHomePage/GameHomePageComponents/UserDashCopyLink";
import { DateTime } from "luxon";
import { hexToRgba } from "../../Brackets/bracketUtils/HexToRgba";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";

const BowlUserDash = ({ 
    userPicksFromDB,
    userBracketPicksFromDB,
    doesUserBelong,
    myUsername,
    gameData,
    setJoinOpen,
    hasJoinDeadlinePassed,
    mainGameId,
    gameAddress,
    formattedGameStartTime,
    myInfo,
    picksThisInterval,
    picksThisIntervalInDB,
    teams,
    howManyRounds,
    hasBracketDeadlinePassed,
    bracketPicksToMake,
    myUserId,
    totalEntrants,
    userInSubgroups,
    bowlGames,
    firstBowlTime,
}) => {

    const {
        myRank,
        myBracketRank,
        myPicksRank,
        myTotalPoints,
        myBracketPoints,
        myPicksPoints,
        overallTied,
        bracketTied,
        picksTied,
    } = myInfo;
    //console.log("My Rank: ", myRank, myBracketRank, myPicksRank);

    const timeNowInNewYork = DateTime.now().setZone('America/New_York');
    const firstBowlTimeInNewYork = DateTime.fromISO(firstBowlTime).setZone('America/New_York');
    const hasFirstBowlStarted = timeNowInNewYork > firstBowlTimeInNewYork;
    //console.log("HAS FIRST BOWL STARTED: ", hasFirstBowlStarted);
  

    // Get today's date in New York time
    const todayInNewYork = DateTime.now().setZone("America/New_York").toISODate(); // 'YYYY-MM-DD'

    const gamesToday = bowlGames?.filter(game => {
            const gameDate = DateTime.fromISO(game.DateTime).setZone("America/New_York").toISODate();
            return gameDate === todayInNewYork;
    })?.map(game => game.GlobalGameID);
    
    //console.log("Games Today: ", gamesToday);
    
    const picksToMakeToday = gamesToday?.length * 2;

    // Count the number of picks in userPicksFromDB where GlobalGameID is in gamesToday
    const picksToday = userPicksFromDB?.filter(pick => gamesToday?.includes(pick.gameId))?.length;
    //console.log("Picks Today: ", picksToday, userPicksFromDB);

    // Find the date of the next game in the future
    const nextGame = bowlGames?.find(game => {
        const gameDate = DateTime.fromISO(game.DateTime).setZone("America/New_York").toISODate();
        return gameDate > todayInNewYork;
    })?.DateTime;
    
    const formattedNextGame = nextGame
        ? DateTime.fromISO(nextGame).setZone("America/New_York").toFormat("EEE MM/dd")
        : null;
    
    //console.log("Next Game: ", nextGame, formattedNextGame);

    const allPicksIn = picksToday === picksToMakeToday;
    //console.log("All Picks In: ", allPicksIn);

    const allPicksMade = picksThisIntervalInDB?.length === picksThisInterval;

    const getLogo = (teamId, teams) => {
        const team = teams?.find((t) => t.GlobalTeamID === teamId);
        return team?.TeamLogoUrl;
    };

    const allBracketPicksMade = userBracketPicksFromDB?.length === bracketPicksToMake;

    const userChampPick = userBracketPicksFromDB?.find((p) => p.round === howManyRounds && p.game === 1);
    const champId = userChampPick?.teamId;
    const champResult = userChampPick?.result;
    //console.log("User Champ Pick: ", userChampPick);

    const champLogo = getLogo(champId, teams);
        
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    


    return (
        <>
        <Box className={"bracket-box-header"} sx={{ height: isMobile ? '95px' : '215px', borderTop: 'none'}}>
        <Box className="super-pickem-user-dash-header" sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>

            <img className="super-pickem-user-dash-trophy" src={bracketTrophy} alt='' />

            <Typography className="super-pickem-user-dash-username" variant="h1" sx={{ display: 'flex', alignItems: 'center' }}>
            {myUsername}

            {!hasJoinDeadlinePassed && (
                <UserDashCopyLink
                mainGameId={mainGameId}
                gameAddress={gameAddress}
                myUserId={myUserId}
                />
            )}
            </Typography>
            
        </Box>
            {doesUserBelong && (
                <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                maxWidth: '1102px',
                margin: '0 auto',
                width:'90%'
                }}>
                {isMobile ? (
                    // These items will be displayed on mobile devices
                    <>
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            height: '100%',  // Ensure consistent height
                        }}
                    >
                    <Typography 
                        className={"super-pickem-user-dash-headings"} 
                        variant='h4' 
                        sx={{ 
                            marginTop: '0px',  // Or a consistent fixed margin
                            textAlign: 'center' 
                        }}
                    >
                        CHAMP
                    </Typography>
                    {champLogo ? (
                        <img 
                        src={champLogo} 
                        alt="Champ Logo" 
                        style={{ 
                            width: '26px', 
                            marginTop: '5px', 
                            border: champResult === "Win" ? '2px solid #00AA72' : champResult === "Loss" ? '2px solid #CC293C' : '',
                            backgroundColor: champResult === "Win" ? hexToRgba('#00AA72', 0.20) : champResult === "Loss" ? hexToRgba('#CC293C', 0.20) : '',
                        }} 
                        />
                    ) : (
                        <Typography 
                        className={"super-pickem-user-dash-values"} 
                        variant='h4' 
                        sx={{ marginTop: '5px', color: '#CC293C' }}
                        >
                        No Pick
                        </Typography>  
                    )}
                    </Box>

                    <Box>
                        <Typography className={"super-pickem-user-dash-headings"} variant='h4'>TOTAL</Typography>
                        <Typography className={"super-pickem-user-dash-values"} variant='h3' sx={{ fontWeight: '800', marginTop: !userInSubgroups ? '5px' : '8px' }}>{`${myTotalPoints} PTS`}</Typography>
                        {!userInSubgroups && 
                            <Typography sx={{ fontSize: '10px', fontWeight: '700' }}>
                                {!hasFirstBowlStarted 
                                    ? `(T1 of ${totalEntrants})` 
                                    : myRank 
                                        ? overallTied 
                                            ? `(T${myRank} of ${totalEntrants})` 
                                            : `(${myRank} of ${totalEntrants})`
                                        : ''
                                }
                            </Typography>
                        }
                    </Box>
                    {hasBracketDeadlinePassed && (
                        <Box>
                            <Typography className={"super-pickem-user-dash-headings"} variant='h4'>BRACKET</Typography>
                            <Typography className={"super-pickem-user-dash-values"} variant='h3' sx={{ fontWeight: '800', marginTop: !userInSubgroups ? '5px' : '8px'}}>{`${myBracketPoints} PTS`}</Typography>
                            {!userInSubgroups && myBracketRank &&
                                <Typography sx={{ fontSize: '10px', fontWeight: '700' }}>{!hasBracketDeadlinePassed ? `(T1 of ${totalEntrants})` : bracketTied ? `(T${myBracketRank} of ${totalEntrants})` : `(${myBracketRank} of ${totalEntrants})`}</Typography>
                            }
                        </Box>
                    )}
                    {hasFirstBowlStarted && (
                        <Box>
                            <Typography className={"super-pickem-user-dash-headings"} variant='h4'>PICK 'EM</Typography>
                            <Typography className={"super-pickem-user-dash-values"} variant='h3' sx={{ fontWeight: '800', marginTop: !userInSubgroups ? '5px' : '8px'}}>{`${myPicksPoints} PTS`}</Typography>
                            {!userInSubgroups && myPicksRank &&
                                <Typography sx={{ fontSize: '10px', fontWeight: '700' }}>{!hasBracketDeadlinePassed ? `(T1 of ${totalEntrants})` : picksTied ? `(T${myPicksRank} of ${totalEntrants})` : `(${myPicksRank} of ${totalEntrants})`}</Typography>
                            }
                        </Box>
                    )}
                    {!hasBracketDeadlinePassed && (
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography className={"super-pickem-user-dash-headings"} variant='h4'>BRACKET IN?</Typography>
                            <Box className="super-pickem-user-dash-values" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5px'}}>
                                {allBracketPicksMade ? (
                                <>
                                    <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    >
                                    <CheckIcon sx={{ color: '#00AA72', fontSize: '23px' }} />
                                    <Typography
                                        sx={{
                                        fontSize: '14px',
                                        color: '#00AA72',
                                        }}
                                    >
                                        ({userBracketPicksFromDB?.length}/{bracketPicksToMake})
                                    </Typography>
                                    </Box>
                                </>
                                ) : (
                                <>
                                    <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    >
                                    <CloseIcon sx={{ color: '#cc293c', fontSize: '23px' }} />
                                    <Typography
                                        sx={{
                                        fontSize: '14px',
                                        color: '#cc293c',
                                        }}
                                    >
                                        ({userBracketPicksFromDB?.length}/{bracketPicksToMake})
                                    </Typography>
                                    </Box>
                                </>
                                )}
                            </Box>
                        </Box>
                    )}
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography className={"super-pickem-user-dash-headings"} variant='h4'>TODAY'S PICKS</Typography>
                        <Box className="super-pickem-user-dash-values" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5px'}}>
                            {gamesToday?.length === 0 ? (
                                <>
                                    <Typography
                                        sx={{
                                            fontSize: '10px',
                                            fontWeight: '700',
                                            color: '#002129',
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        Next Game: {"\n"}
                                        {formattedNextGame}
                                    </Typography>
                                </>
                            ) : allPicksIn ? (
                                <>
                                <CheckIcon sx={{ color: '#00AA72', fontSize: '23px' }} />
                                <Typography
                                    sx={{
                                    fontSize: '14px',
                                    color: '#00AA72',
                                    marginLeft: '5px',
                                    }}
                                >
                                    ({picksToday}/{picksToMakeToday})
                                </Typography>
                                </>
                            ) : (
                                <>
                                <CloseIcon sx={{ color: '#CC293C', fontSize: '23px' }} />
                                <Typography
                                    sx={{
                                    fontSize: '14px',
                                    color: '#CC293C',
                                    marginLeft: '5px',
                                    }}
                                >
                                    ({picksToday}/{picksToMakeToday})
                                </Typography>
                                </>
                            )}
                        </Box>
                    </Box>
                    </>
                ) : (
                    // These items will be displayed on desktop devices
                    <>
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            height: '100%',  // Ensure consistent height
                        }}
                    >
                    <Typography 
                        className={"super-pickem-user-dash-headings"} 
                        variant='h4' 
                        sx={{ 
                            marginTop: '0px',  // Or a consistent fixed margin
                            textAlign: 'center' 
                        }}
                    >
                        CHAMP
                    </Typography>

                    {champLogo ? (
                        <img 
                        src={champLogo} 
                        alt="Champ Logo" 
                        style={{ 
                            width: '50px', 
                            marginTop: '5px', 
                            border: champResult === "Win" ? '2px solid #00AA72' : champResult === "Loss" ? '2px solid #CC293C' : '',
                            backgroundColor: champResult === "Win" ? hexToRgba('#00AA72', 0.20) : champResult === "Loss" ? hexToRgba('#CC293C', 0.20) : '',          
                        }} 
                        />
                    ) : (
                        <Typography 
                            className={"super-pickem-user-dash-values"} 
                            variant='h4' 
                            sx={{ marginTop: '10px', color: '#CC293C' }}
                        >
                            No Pick
                        </Typography>  
                    )}
                    </Box>
                    <Box>
                        <Typography className={"super-pickem-user-dash-headings"} variant='h4'>TOTAL</Typography>
                        <Typography className={"super-pickem-user-dash-values"} variant='h4' sx={{ marginTop: '10px'}}>{`${myTotalPoints} PTS`}</Typography>
                        {!userInSubgroups && 
                            <Typography sx={{ fontSize: '16px', fontWeight: '700' }}>
                                {!hasFirstBowlStarted 
                                    ? `(T1 of ${totalEntrants})` 
                                    : myRank 
                                        ? overallTied 
                                            ? `(T${myRank} of ${totalEntrants})` 
                                            : `(${myRank} of ${totalEntrants})`
                                        : ''
                                }
                            </Typography>
                        }
                    </Box>
                    {hasBracketDeadlinePassed && (
                        <Box>
                            <Typography className={"super-pickem-user-dash-headings"} variant='h4'>BRACKET</Typography>
                            <Typography className={"super-pickem-user-dash-values"} variant='h4' sx={{ marginTop: '10px'}}>{`${myBracketPoints} PTS`}</Typography>
                            {!userInSubgroups && myBracketRank &&
                            <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>{!hasBracketDeadlinePassed ? `(T1 of ${totalEntrants})` : bracketTied ? `(T${myBracketRank} of ${totalEntrants})` : `(${myBracketRank} of ${totalEntrants})`}</Typography>
                            }
                        </Box>
                    )}
                    {hasFirstBowlStarted && (
                        <Box>
                            <Typography className={"super-pickem-user-dash-headings"} variant='h4'>PICK 'EM</Typography>
                            <Typography className={"super-pickem-user-dash-values"} variant='h4' sx={{ marginTop: '10px'}}>{`${myPicksPoints} PTS`}</Typography>
                            {!userInSubgroups && myPicksRank &&
                            <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>{!hasBracketDeadlinePassed ? `(T1 of ${totalEntrants})` : picksTied ? `(T${myPicksRank} of ${totalEntrants})` : `(${myPicksRank} of ${totalEntrants})`}</Typography>
                            }
                        </Box>
                    )}
                    {!hasBracketDeadlinePassed && (
                        <Box sx={{ textAlign: 'center' }}>
                        <Typography className={"super-pickem-user-dash-headings"} variant='h4'>BRACKET IN?</Typography>
                        <Box className="super-pickem-user-dash-values" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px'}}>
                            {allBracketPicksMade ? (
                            <>
                                <CheckIcon sx={{ color: '#00AA72', fontSize: '44px' }} />
                                <Typography
                                sx={{
                                    fontSize: '32px',
                                    color: '#00AA72',
                                    marginLeft: '5px',
                                }}
                                >
                                ({userBracketPicksFromDB?.length}/{bracketPicksToMake})
                                </Typography>
                            </>
                            ) : (
                            <>
                                <CloseIcon sx={{ color: '#CC293C', fontSize: '44px' }} />
                                <Typography
                                sx={{
                                    fontSize: '32px',
                                    color: '#CC293C',
                                    marginLeft: '5px',
                                }}
                                >
                                ({userBracketPicksFromDB?.length}/{bracketPicksToMake})
                                </Typography>
                            </>
                            )}
                        </Box>
                        </Box>
                    )}
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography className={"super-pickem-user-dash-headings"} variant='h4'>TODAY'S PICKS</Typography>
                        <Box
                            className="super-pickem-user-dash-values"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '10px',
                            }}
                        >
                            {gamesToday?.length === 0 ? (
                                <>
                                    <Typography
                                        sx={{
                                            fontSize: '18px',
                                            fontWeight: '700',
                                            color: '#002129',
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        Next Game: {"\n"}
                                        {formattedNextGame}
                                    </Typography>
                                </>
                            ) : allPicksIn ? (
                                <>
                                <CheckIcon sx={{ color: '#00AA72', fontSize: '44px' }} />
                                <Typography
                                    sx={{
                                    fontSize: '32px',
                                    color: '#00AA72',
                                    marginLeft: '5px',
                                    }}
                                >
                                    ({picksToday}/{picksToMakeToday})
                                </Typography>
                                </>
                            ) : (
                                <>
                                <CloseIcon sx={{ color: '#CC293C', fontSize: '44px' }} />
                                <Typography
                                    sx={{
                                    fontSize: '32px',
                                    color: '#CC293C',
                                    marginLeft: '5px',
                                    }}
                                >
                                    ({picksToday}/{picksToMakeToday})
                                </Typography>
                                </>
                            )}
                        </Box>

                    </Box>
                    </>
                )}
                </Box>
            )}
            {!doesUserBelong && (
                <Button 
                    variant="contained" 
                    onClick={() => setJoinOpen(true)}
                    sx={{ 
                        fontSize: '14px', 
                        fontWeight: '700', 
                        width: '100px', 
                        height: '36px', 
                        whiteSpace: 'nowrap',
                        marginTop: isMobile ? '0px' : '24px',
                    }}
                >
                    JOIN POOL
                </Button>
            )}
            </Box>
        </>
    );
}

export default BowlUserDash;
import React, { useState } from "react";
import { Box, Typography, Snackbar, Alert } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const UserDashCopyLink = ({ mainGameId, gameAddress, myUserId }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleCopyClick = () => {

        let link = `https://poolpartygaming.com/${gameAddress}/${mainGameId}/HOME/HOME`;

        // If the user is logged in, append the user ID to the link
        if (myUserId) {
            link += `?referralId=${myUserId}`;
        }

        // Copy the link to the clipboard

        navigator.clipboard.writeText(link).then(() => {
            setSnackbarOpen(true); // Open the snackbar when the link is copied
        }).catch((err) => {
            console.error('Failed to copy text: ', err);
        });
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return; // Prevent closing when clicking away
        }
        setSnackbarOpen(false); // Close the snackbar
    };

    return (
        <Box>
            <Typography
                sx={{
                    color: '#00AAD1',
                    textDecoration: 'underline',
                    fontSize: '14px',
                    fontWeight: '700',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '10px',
                    cursor: 'pointer' // Change cursor to pointer to indicate it's clickable
                }}
                onClick={handleCopyClick} // Make the text clickable
            >
                Referral Link
                <ContentCopyIcon sx={{ marginLeft: '4px' }} />
            </Typography>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000} // The snackbar will automatically close after 3 seconds
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Link copied to clipboard!
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default UserDashCopyLink;

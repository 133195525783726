import React from "react";
import { Box, Typography, Button } from "@mui/material";
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const KOTNForHomePage = () => {

    const navigateToTop = useNavigateToTop();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    return (
        <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '32px',
        }}
      >
      <Box>
      <Box
        className="our-pools-container"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 17px',
          boxSizing: 'border-box',
          gap: '16px',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          textAlign: 'left',
          fontSize: '18.3px',
          color: '#000',
          fontFamily: 'Inter',
          maxWidth: '435px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Box
            sx={{
              height: '43px',
              position: 'relative',
              letterSpacing: '-0.02em',
              lineHeight: '150%',
              display: 'inline-block',
              marginBottom: isMobile ? '0' : '24px',
            }}
          >
            <img
                loading="lazy"
                alt="KOTN"
                src="https://poolpartyfilestorage.blob.core.windows.net/media/KotnLogoSquare.png"
                style={{
                    width: isMobile ? '120px' : '135px', // Control size for mobile and desktop
                    height: 'auto', // Maintain aspect ratio
                    marginRight: '16px', // Space between image and text
                    verticalAlign: 'middle', // Align image with text
                    margin: '0 auto'
                }}
            />
          </Box>
        </Box>
       
      </Box>
      <Box
        sx={{
          maxWidth: isMobile ? '295px' : '420px',
          margin: '0 auto',
          marginTop: isMobile ? '32px' : '80px',
        }}
      >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#5D7CC4',
                    border: '4px solid #000',
                    padding: '16px 8px',
                    marginTop: '100px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? '14px' : '18px',
                        fontWeight: 700,
                        color: '#fff'
                    }}
                >
                    The Premier College Football Bowl Pool in the North.
                </Typography>
                <Typography
                    sx={{
                        fontSize: isMobile ? '12px' : '16px',
                        fontWeight: 700,
                        color: '#fff',
                    }}
                >
                    (Undoubtedly better than any in the South.)
                </Typography>
            </Box>
            <Typography
                sx={{
                fontSize: isMobile ? '14px' : '16px',
                fontWeight: 500,
                textAlign: 'left', // Aligns the entire text to the left
                marginTop: '10px',
                marginBottom: '8px',
                }}
            >
                <span style={{ fontWeight: 700 }}>Pick the Bracket</span> -  Think NCAA March Madness Bracket. But for the College Football Playoff Bracket.
            </Typography>
          <Typography
            sx={{
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: 500,
              textAlign: 'left',
            }}
          >
            <span style={{ fontWeight: 700 }}>Pick the Games</span> -  For all 20 Northern Team Bowls, pick the winner ATS and the O/U points total.
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              navigateToTop(`/super-pickem-cfb/${uriEncoded('eb8a1c14-f716-456d-9bbd-b2e0fd90b5ec')}/HOME/HOME`)
            }            sx={{
              marginTop: '24px',
              width: '120px',
              whiteSpace: 'nowrap',
            }}
          >
            POOL HOME
          </Button>
      </Box>
      </Box>
      {!isMobile && (
        <Box>
        <img
          loading="lazy"
          alt="Pool Party Gaming"
          src="https://poolpartyfilestorage.blob.core.windows.net/media/PoolPartyGamingLogoWide.png" 
          style={{
            height: '240px',
            width: '300px',
            position: 'relative',
            objectFit: 'contain',
            marginLeft: '117px',
          }}
        />
        </Box>
      )}
      </Box>
  );
};

export default KOTNForHomePage;
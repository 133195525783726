import React, { useState } from 'react';
import { Box, Typography, Modal, Button } from '@mui/material'; // Import MUI components
import SponsorSelectionButtons from './SponsorSelectionButtons';
import SponsorStoryPage from './SponsorStoryPage';
import SponsorHighlightsPage from './SponsorHighlightsPage';
import SponsorSisterLocationsPage from './SponsorSisterLocationsPage';
import SponsorMerchPage from './SponsorMerchPage';
import './SponsorTab.scss'; // Import SCSS file

const SponsorTab = ({ gameData }) => {
    
    const mainGameId = gameData?.gameID;
    const gameAddress = gameData?.gameAddress;
    const sponsorName = gameData?.sponsorInfo?.sponsorName;
    const sponsorSecondaryLogo = gameData?.sponsorInfo?.sponsorSecondaryLogo;
    const sponsorAbbreviation = gameData?.sponsorInfo?.sponsorAbbreviation;
    const sponsorWebsite = gameData?.sponsorInfo?.sponsorWebsite;
    const dishes = gameData?.sponsorInfo?.dishes;
    const sisterLocations = gameData?.sponsorInfo?.sisterLocations;
    const merch = gameData?.sponsorInfo?.merch;
    //console.log("Dishes: ", dishes);

    const buttonsToDisplay = [`${sponsorAbbreviation} Story`];

    // Only add "Highlights" if dishes exist and have items
    if (dishes && dishes?.length > 0) {
        buttonsToDisplay.push('Highlights');
    }

    if (sisterLocations && sisterLocations?.length > 0) {
        buttonsToDisplay.push('Sister Locations');
    }

    if (merch && merch?.length > 0) {
        buttonsToDisplay.push(`Merch`);
    }

    const sponsorInfo = gameData?.sponsorInfo;
    const sponsorTabImage = gameData?.sponsorInfo?.sponsorTabImage;
    const sponsorTabLinkText = gameData?.sponsorInfo?.sponsorTabLinkText;
    //console.log("Sponsor Info: ", sponsorInfo);


    const [sponsorPageState, setSponsorPageState] = useState(buttonsToDisplay[0]);
    //console.log(sponsorPageState);
   

  return (
    <>
        <Box className="sponsor-tab-image-container">
            <img
                src={sponsorTabImage}
                alt={sponsorName}
                className="sponsor-tab-sponsor-header-image"
            />
        </Box>
        <Typography className="sponsor-tab-main-sponsor-link">
            <a href={sponsorWebsite} target="_blank" rel="noopener noreferrer">
                {sponsorTabLinkText ? sponsorTabLinkText : `${sponsorName} Website`}
            </a>
      </Typography>
        <SponsorSelectionButtons 
            sponsorPageState={sponsorPageState} 
            setSponsorPageState={setSponsorPageState} 
            buttonsToDisplay={buttonsToDisplay}
            mainGameId={mainGameId}
            gameAddress={gameAddress}
        />

        {sponsorPageState === `${sponsorAbbreviation} Story` && 
            <SponsorStoryPage
                sponsorInfo={sponsorInfo}
            />
        }
        {sponsorPageState === 'Highlights' && 
            <SponsorHighlightsPage
                dishes={sponsorInfo?.dishes}
                storyBottomText={sponsorInfo?.storyBottomText}
                highlightsText={sponsorInfo?.highlightsText}    
            />
        }
        {sponsorPageState === 'Sister Locations' && 
            <SponsorSisterLocationsPage
                sisterLocations={sisterLocations}
                storyBottomText={sponsorInfo?.storyBottomText}
                highlightsText={sponsorInfo?.highlightsText}
            />
        }

        {sponsorPageState === `Merch` && 
            <SponsorMerchPage
                merch={merch}
                storyBottomText={sponsorInfo?.storyBottomText}
                highlightsText={sponsorInfo?.highlightsText}
                sponsorSecondaryLogo={sponsorSecondaryLogo}
            />
        }
    </>
  );
}

export default SponsorTab;
import React, { useState, useEffect } from "react";
import { Typography, Button, Box, Modal } from "@mui/material";
import './GameHomePage.scss';
import CreatePartyInfo from "../MyParty/CreatePartyInfo";
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DateTime } from "luxon";
import CountdownClock from "./GameHomePageComponents/CountdownClock";
import ReferralRewards from "./GameHomePageComponents/ReferralRewards";
import PoolPrizes from "./GameHomePageComponents/PoolPrizes";
import RankingsTable from "./GameHomePageComponents/RankingsTable";
import HowToPlay from "./GameHomePageComponents/HowToPlay";
import PoolPreviewVideo from "./GameHomePageComponents/PoolPreviewVideo";
import PoolScreenshots from "./GameHomePageComponents/PoolScreenshots";
import TextAcceptanceModal from "../GameModals/JoinGameModal/TextAcceptanceModal";

const GameHomePageNew = ({
    homePageProps: {
        setSignUpState,
        gameAddress,
        gameData,
        userSubgroups,
        doesUserBelong,
        myUserId,
        setPartyState,
        partyId,
        setPartyId,
        setDefaultPartyId,
        setDefaultPartyName,
        mainGameId,
        setJoinOpen,
        hasJoinDeadlinePassed,
        countdownTimer,
        countdownMessage,
        timerDateTime,
        fullLeaderboard,
        referralPrizes,
        amIOptedInToTexts,
        countdownText,
        countdownBackground,
        firstBowlTime,
        bowlPicks
    }
}) => {
    const gameName = gameData?.gameName;
    const homePageInfo = gameData?.homePage;
    const rewardsLeaderboard = referralPrizes?.rewardsLeaderboard;
    const rewardsText = referralPrizes?.rewardsText;
    const prizeInfo = homePageInfo?.prizes;
    const sponsorInfo = gameData?.sponsorInfo;
    const sponsorName = sponsorInfo?.sponsorName;
    const sponsorLogo = sponsorInfo?.sponsorLogo;
    const sponsorWebsite = sponsorInfo?.sponsorWebsite;
    const lastDayToJoin = gameData?.lastDayToJoin;
    const howToPlayInfo = homePageInfo?.howToPlayInfo;

    const timeNowInNy = DateTime.now().setZone('America/New_York');
    const firstBowlTimeInNy = DateTime.fromISO(firstBowlTime, { zone: "America/New_York" });
    const hasFirstBowlStarted = timeNowInNy > firstBowlTimeInNy;
    //console.log("Has First Bowl Started: ", hasFirstBowlStarted);

    const countdownTimerMessaging = (!hasFirstBowlStarted && bowlPicks) ? "First Game Starts In" : countdownMessage;
    const countdownTimerDateTime = (!hasFirstBowlStarted && bowlPicks) ? firstBowlTime : timerDateTime;
    //console.log("Countdown Timer DateTime: ", countdownTimerDateTime, timerDateTime);


    //Parse the date string in ET (Eastern Time) zone
    const date = DateTime.fromISO(lastDayToJoin, { zone: "America/New_York" });

    //Format the date to the desired output
    const formattedDate = date?.toFormat('cccc LLL d @ h a') + ' ET';

    const prizes = gameData?.prizes;
    const poolPreviewVideo = gameData?.homePage?.poolPreviewVideo;
    const screenshots = gameData?.homePage?.screenshots;
    //console.log("screenshots: ", screenshots);
    const logo = gameData?.clubLogo;

    const navigateToTop = useNavigateToTop();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState(null);
    const [textOptInModalOpen, setTextOptInModalOpen] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
        setModalImage(null);
    }

    const handleOpenModal = (image) => {
        setShowModal(true);
        setModalImage(image);
    }

    const handleTextOptInClick = () => {
        setTextOptInModalOpen(true);
    }

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const handleRulesClick = () => {
        navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/RULES/RULES`);
    }

   const handleJoinClick = () => {
            //console.log("Setting Join Open True")
            setJoinOpen(true);
    }

    const timeNowInNewYork = DateTime.now().setZone('America/New_York');
    const hasCounterDeadlinePassed = timeNowInNewYork > DateTime.fromISO(timerDateTime);

    return (
        <div>
            {!doesUserBelong && !countdownTimer && (
                <Typography className="game-home-page-join-deadline">
                    Entry Deadline: {formattedDate}
                </Typography>
            )}

            {countdownTimer && !hasCounterDeadlinePassed && (
                <CountdownClock
                    title={countdownTimerMessaging}
                    targetTimeISO={countdownTimerDateTime}
                    countdownText={countdownText}
                    countdownBackground={countdownBackground}
                />
            )}

            {doesUserBelong && !amIOptedInToTexts && (
                <Button
                    variant="contained"
                    sx={{
                        marginTop: '20px',
                    }}
                    onClick={handleTextOptInClick}
                >
                    TEXT MESSAGE PICK REMINDERS?
                </Button>
            )}

            <ReferralRewards
                mainGameId={mainGameId}
                gameAddress={gameAddress}
                myUserId={myUserId}
                rewardsLeaderboard={rewardsLeaderboard}
                rewardsText={rewardsText}
                hasJoinDeadlinePassed={hasJoinDeadlinePassed}
            />

            {doesUserBelong && (
                <Box
                    sx={{
                        backgroundColor: "#002129",
                        padding: '16px 0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '24px',
                            fontWeight: '700',
                            color: '#fff',
                            marginBottom: userSubgroups?.length > 0 ? '0px' : '16px',
                        }}
                    >
                        MY PARTY
                    </Typography>

                    {userSubgroups?.length > 0 && (
                        <RankingsTable
                            gameData={gameData}
                            myUserId={myUserId}
                            gameAddress={gameAddress}
                            mainGameId={mainGameId}
                            userSubgroups={userSubgroups}
                            setPartyState={setPartyState}
                            partyId={partyId}
                            setPartyId={setPartyId}
                            setDefaultPartyId={setDefaultPartyId}
                            setDefaultPartyName={setDefaultPartyName}
                            leaderboard={fullLeaderboard}
                            hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                            gameName={gameName}
                        />
                    )}

                    <CreatePartyInfo
                        setSignUpState={setSignUpState}
                        gameAddress={gameAddress}
                        doesUserBelong={doesUserBelong}
                        gameName={gameName}
                        setJoinOpen={setJoinOpen}
                        flipColor={true}
                    />
                </Box>
            )}

            <PoolPrizes
                doesUserBelong={doesUserBelong}
                prizes={prizeInfo}
            />

            <HowToPlay
                doesUserBelong={doesUserBelong}
                hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                gameAddress={gameAddress}
                mainGameId={mainGameId}
                setJoinOpen={setJoinOpen}
                howToPlayInfo={howToPlayInfo}
            />

            {poolPreviewVideo && (
                <PoolPreviewVideo
                    poolPreviewVideo={poolPreviewVideo}
                    doesUserBelong={doesUserBelong}
                />
            )}

            {screenshots && (
                <PoolScreenshots
                    screenshots={screenshots}
                    doesUserBelong={doesUserBelong}
                    handleOpenModal={handleOpenModal}
                />
            )}

            {textOptInModalOpen && (
                <TextAcceptanceModal
                    joinOpen={textOptInModalOpen}
                    setJoinOpen={setTextOptInModalOpen}
                    status={"success"}
                    logo={logo}
                    myUserId={myUserId}
                    gameId={mainGameId}
                    preChecked={true}
                />
            )}

            {showModal && (
                <Modal
                    open={showModal}
                    onClose={handleCloseModal}
                    sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0 1rem',
                    }}
                >
                    <Box
                    sx={{
                        width: isMobile ? '300px' : '600px',
                        height: isMobile ? '500px' : '750px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        overflow: 'hidden',
                        boxSizing: 'border-box', // Ensure padding is included in the dimensions
                    }}
                    >
                    <Box
                        sx={{
                        width: '100%',
                        height: '95%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflowY: 'auto',
                        }}
                    >
                        <img
                        src={modalImage}
                        alt="Image placeholder"
                        style={{
                            objectFit: 'contain',
                            maxWidth: '100%',
                            maxHeight: 'calc(100% - 50px)', // Ensure button space
                        }}
                        />
                        <Button
                            variant="contained"
                            onClick={handleCloseModal}
                            sx={{
                                marginTop: '10px',
                            }}
                        >
                            CLOSE
                        </Button>
                    </Box>
                    </Box>
                </Modal>
            )}
        </div>
    )
}

export default GameHomePageNew;
